.select-batch {
  background-color: #fff;
  padding: 15px 20px 15px 20px;
  border-radius: 8px;
}

.flex {
  display: flex;
}

.p-white {
  padding: 16px 20px 16px 20px;
  border-radius: 8px;
}

.p-16-4 {
  padding: 4px 16px 4px 16px;
}

.p-32-4 {
  padding: 1rem 4rem;
}

.bg-white {
  background-color: #fff;
}

.br-10 {
  border-radius: 10px;
}

.batch-name {
  font-weight: bold;
}

.font-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.gap-8 {
  gap: 2rem;
}

.gap-4 {
  gap: 4px;
}

.gap-16 {
  gap: 16px;
}

.gap-32 {
  gap: 32px;
}

.gap-80 {
  gap: 80px;
}

.w-100 {
  width: 100%;
}

.align-center {
  align-items: center;
}

.edit-batch {
  color: #666;
  font-size: 12px;
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.my-2 {
  margin-top: 2px;
}

.my-4 {
  margin-top: 4px;
}

.my-16 {
  margin-top: 16px;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.font-medium {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.grow-1 {
  flex-grow: 1;
}

.overflow-y-auto {
  height: 80%;
  overflow-y: auto;
}

w-auto {
  flex: 0 0 auto;
}

::-webkit-scrollbar {
  width: 8px;
}
