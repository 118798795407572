.footerText{
  font-family: Heebo;
  font-size: 12px;
  align-items: right;
  line-height: 30px;
  letter-spacing: 0.24px;
  color: #8c8c8c;
}
.footerTextLast{
  font-family: Heebo;
  font-size: 12px;
  align-items: center;
  line-height: 100px;
  letter-spacing: 0.24px;
  padding-left: 475px;
  color: #8c8c8c;
}