.bookcover {
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
  }
  .bookcover-bordered {
    border-radius: 6px;
    border: 1px solid #cdcdcd;
  }
  .loading-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    color: #ffffff;
  }
  
  ._book-cntr {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    flex-grow: 1;
    padding-top: 48px;
  }
  
  ._book_meta_cntr {
    height: 100%;
    background-color: #333333;
  }
  
  .book-meta-cntr .page-cntr {
    background-color: #333333;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  li.page-cntr{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .book-reader-menu {
    position: absolute;
    height: 50px;
    width: 100px;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #fff;
  }
  
  .page-numbers {
    position: absolute;
    height: 50px;
    width: 100px;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #aaaaaa;
  }
  
  #current-page {
    font-size: 1.8rem;
  }
  
  #number-of-pages {
    font-size: 1.2rem;
  }
  
  .react-pdf__Page__canvas{margin: auto;}

  .glide__arrow{
    border: none !important;
    padding: 9px 2px;
    border-radius: 0px;
  }
  .react-pdf__Page{display: flex; justify-content: center;}
  .glide__track{background-color: #333;}
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .glide__arrow--left{
      left: 0px;
     }
     
     .glide__arrow--right{
       right: 0px;
     }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    ._book-cntr {
      padding-top: 0px;
    }
  }
  