.capsule-cntr{
  border-top: 1px solid #C4C4C4;
  padding: 2.5rem 0rem;
  width: 90%;
  max-width: 1005px;
  margin: auto;
}

.capsule-blk{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.capsule-left{
  width: 55%;
}

.capsule-right{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.-step{
  border-top: 1px solid #E5E5E5;
}
.capsule-right img{
  max-width: 100%;
}
.action-cntr{display: flex; padding-top: 16px;}
.action-btn-capsule{background-color: #7CBA00 !important;}
.action-btn-capsule .btn-text{color: #FFF!important; font-size: 0.8rem;}

.ch-description {
  font-size: 16px;
}
