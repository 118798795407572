.p_muitabs_indicator {
    background-color: #E4001B !important;
    height: 3px;
}

.p_muitab {
    justify-content: flex-start !important;
    text-align: left !important;
    margin: 6px 0px !important;
}

.p_muitab_selected span {
    color: #E4001B;
}

.p_muitab_selected::before {
    /* content: '\25BA'; */
    color: grey
}

.p_tab-wrapper {
    align-items: start !important;
    opacity: 1;
    white-space: normal !important;
    font-size: 17px !important;
    line-height: 1.4 !important;
    text-transform: initial !important;
}

.title-font {
    font: normal normal medium 28px/47px ff-tisa-sans-web-pro;
    color: #E4001B;
    letter-spacing: 0px;
    opacity: 1;
}

.tab-top-wrapper {
    opacity: 1;
    white-space: nowrap !important;
}
.custom_tab_root {
    max-width: none !important;
}

.grey {
    color: grey !important;
    margin-left: 0px;
}

.tab-scroll {
    overflow-y: scroll !important;
    height: 469px;
}

.how-to-icon {
    margin-top: 4px;
    width: 85%;
    height: 25
}

.bottom_border {
    border-bottom: 1px solid #CCC;
    padding-bottom: 25px
}
.img_resource{
    margin-top:7px;
    cursor: pointer;    
}
.typo-text-active {
    color: #BA1F00;
    opacity: 1;
    cursor: pointer;
}

.typo-text-inactive {
    color: grey;
    font-weight: 500;
}