.svg-container [role=toolbar] {
background-color: transparent !important;
}

.svg-container [role=toolbar] button{
 fill: #777 !important;
}

.select-label{
 padding: 8px; background-color: aliceblue;
}