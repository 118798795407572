/* .errorMsg{
  height: 1.375em;
}
.IllumineLogo103x122 {
  width: 34px;
  height: 40px;
  object-fit: contain;
}
.PedgogLogo2498 {
  width: 28.4px;
  height: 40.3px;
  object-fit: contain;
}

.Group-2231 {
  width: 27.9px;
  height: 27.9px;
  object-fit: contain;
}
.Group-2226 {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.Group-2227 {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.Group-2229 {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.Explore {
  width: 34px;
  height: 14px;
  font-family: Heebo;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ba1f00;
} */

/* .menu {
  z-index: 1301
} */

.Group-2225 {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.menu ul {
  list-style-type: none;
  width: 70px;
  height: 100%;
  background-color: #e9e9e9;
}

.menu ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  flex-direction: column;
}

.menu ul li span {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 48px;
  transition: 0.2s ease-in-out;
  border-radius: 5px;
  background: transparent;
  flex-direction: column;
  text-align: center;
}

.menu ul li span.active {
  height: 50px;
  background: #fff;
}

.menu ul li span.active a img {
  filter: invert(100%) sepia(700%) saturate(9500%) hue-rotate(20deg) brightness(90%);
}

.menu ul li span.active a label {
  display: none;
}

.menu ul li span.active {
  background: #fff;
  height: 50px;
}

.menu ul li a {
  display: block;
  align-items: center;
  justify-content: center;
  /* width: 48px; */
  /* height: 48px;
	line-height: 48px; */
  transition: 0.2s ease-in-out;
  border-radius: 5px;
  background: transparent;
  /* position: absolute; */
}

.menu ul li:hover {
  filter: none
}

.menu ul li:hover a {
  filter: invert(100%) sepia(700%) saturate(9500%) hue-rotate(20deg) brightness(90%);
}

.menu ul li:hover span.active a {
  filter: none;
}

.h-100 {
  height: 100% !important;
}

.menu ul li label, .last-div-label {
  position: relative;
  display: block;
  align-items: center;
  justify-content: center;
  /* width: 34px; */
  height: 14px;
  font-family: Heebo;
  /* margin-top: 70%; */
  font-size: 70%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #8c8c8c;
}

#btn-Add {
  position: absolute;
  bottom: 18%;
}

#btn-Add img {
  width: 25px;
}

#btn-logout {
  position: absolute;
  bottom: 5%;
}

.last-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 180px;
}