@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Devanagari:wght@100;200;300;400;500;600;700;800;900&&display=swap');
* {
  font-family: 'Heebo', sans-serif;
}

.subheading {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #8c8c8c;
}

.subtitle {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #8d8d8d;
}

h1 {
  font-size: 1.625rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

h3 {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

h6 {
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #28282c;
  /* text-transform: capitalize; */
}

.merriweather-sans {
  font-family: 'Merriweather Sans', sans-serif;
}

.work-sans {
  font-family: 'Work Sans', sans-serif;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

.tablet-gothic {
  font-family: tablet-gothic, sans-serif;
}

input[type="password"] {
  letter-spacing: 1px;
}

.soure-serif {
  font-family: 'Source Serif Pro', serif;
}

.bierstadt-regular {
  font-family: 'Bierstadt-regular' !important;
}

.ir-tisa-font * {
  font-family: ff-tisa-sans-web-pro !important;
  /* font-size: 1.615vw; */
  font-feature-settings: 'lnum' 1;
}

.ir-tisa-font {
  font-family: ff-tisa-sans-web-pro !important;
  font-size: 1.615vw;
  font-feature-settings: 'lnum' 1;
}

.ir-noto-devanagiri-font {
  font-family: 'Noto Serif Devanagari', serif;
  font-size: 1.615vw;
  font-feature-settings: 'lnum' 1;
}

.ir-noto-devanagiri-font * {
  font-family: 'Noto Serif Devanagari', serif;
  font-size: 1.615vw;
  font-feature-settings: 'lnum' 1;
}