.prep-tab-root.prep-tab-selected {
  background-color: #fafafa;
  color: #000000
}

.scrollable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollable::-webkit-scrollbar-track {
  box-shadow: none;
  margin: 10px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  /* margin: 10px; */
  /* outline: 1px solid slategrey; border-radius: 3px; */
}

/* 
.scrollable {
  overflow: scroll;
} */

.w-100 {
  width: 100%;
}

.float-left {
  float: left;
}

.text-center {
  text-align: center;
}

/* .btn {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #28282c;
  border-radius: 3px;
} */

.btn-yellow {
  background-color: #ffbc36 !important;
  border: 1px solid #ffbc36 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
}

.video-react {
  margin: auto !important;
}

.ml-3 {
  margin-left: 15px !important;
}

.sticky {
  position: sticky !important;
  bottom: 5% !important;
  z-index: 9px !important;
  right: 10%;
}

.float-right {
  float: right;
}