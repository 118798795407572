.activePiece {
  border: 1px solid #ffbc36;
}

.inActivePiece {
  border: 1px solid #ffffff00;
}

.piece-group * {
  transition-property: x, y, cx, cy, dy;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.Four_div {
  display: flex;
  flex-direction: column;
  padding: 13px 18px;
  border-radius: 8px;
  flex-grow: 1;
}
.extra {
  height: 90%;
  line-height: 1.5rem;
  font-size: 0.8rem;
}

.display-hover {
  display: none !important;
}

.response-box1:hover .display-hover {
  display: flex !important;
  cursor: pointer;
}

.piece-card-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}
