.drawer-paper {
  top: unset !important;
  width: 28%;
  height: 100%;
}

.drawer-docked {
  width: 28%;
  /* width: 23%; */
  height: 100%;
  flex-shrink: 0;
}

.Collapsible__contentInner {
  margin-bottom: 1.2em;
}

/* 
.MuiDrawer-paperAnchorDockedLeft {.
  overflow: scroll;
} */

.Collapsible {
  padding: 1.5rem;
  background-color: #fcfcfc;
}

.chevron {
  color: #ba1f00;
  transition: transform 400ms !important;
  transition: color 400ms !important;
}

.Collapsible__trigger.is-open .chevron {
  transform: rotate(180deg);
  color: #8c8c8c;
}

.tool-cntr {
  display: flex;
  cursor: pointer;
  margin: 0 -1em;
  /* margin-top: 0.5rem; */
  /* padding: 1rem 2.5rem 1rem 1.5rem; */
  border-bottom: 1px solid #e6e6e6;
  transition: all 0.3s ease;
}

.tool-id {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  /* background-color: #e9e9e9; */
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.43;
  letter-spacing: 0.28px;
  text-align: center;
}

.active {
  background-color: #efefef;
  /* margin: 0 -1em;
  margin-top: 0.5rem;
  padding: 0.5em 1em; */
  color: #ba1f00;
}

.active .tool-id {
  background-color: #ba1f00;
  color: #fff;
}

.active-section {
  background-color: #fcfcfc;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.drawer-paper::-webkit-scrollbar {
  width: 6px;
}

.drawer-paper::-webkit-scrollbar-track {
  box-shadow: none;
}

.drawer-paper::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  outline: 1px solid slategrey;
  border-radius: 3px;
}

.conduct-roadmap-title {
  font-weight: bold;
  font-size: 0.8em;
  /* line-height: 24px; */
  /* letter-spacing: 0.18px; */
  color: #252525;
}

.conduct-module-subtitle {
  font-size: 0.7em;
  /* line-height: 1.3em; */
  /* letter-spacing: 0.14px; */
  color: #898989;
}

.conduct-module-title {
  /* font-size: 1em; */
  line-height: 1.2em;
  /* letter-spacing: 0.22px; */
  color: #7b7b7b;
  font-weight: bold;
}

.conduct-module-time {
  letter-spacing: 0.12px;
  font-size: 0.7em;
  line-height: 26px;
  color: #898989;
  margin-top: 6px;
}

.conduct-logo {
  width: 1.5em;
  height: 2em;
  margin-right: 0.75em;
}

.conduct-session-roadmap-text {
  font-size: 0.6em;
  /* padding: 1em 1.6em !important; */
  /* line-height: 26px; */
  /* letter-spacing: 0.72px; */
  color: #898989;
}

.drawer-padding {
  padding: 0.8em;
}

.roadmap-index {
  border: 1px solid black;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  margin-right: 15px;
}

.conduct-roadmap-time {
  font-size: 12px !important;
  line-height: 26px;
  letter-spacing: 0.12px;
  color: #898989;
}

.conduct-end-session {
  display: flex;
  color: #ff3100;
  /* font-size: 24px !important; */
  /* line-height: 26px; */
  /* letter-spacing: 0.24px; */
  background: #ffe8e3 0% 0% no-repeat padding-box;
  border-top: 2px solid #ff3100;
  border-top-width: 3.5px;
  cursor: pointer;
  margin-top: auto;
}

.intro-unit-dot {
  width: 1em;
  height: 1em;
  /* width: 0.75em;
  height: 0.75em; */
  /* margin-right: 1rem;
  margin-left: 0.75rem; */
  margin: auto;
  background-color: #7b7b7b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 0px 0px 0.35em white, 0px 0px 0px 0.4em #7B7B7B; */
}

.active #IntroDot {
  background-color: #ba1f00;
  /* box-shadow: 0px 0px 0px 0.35em white, 0px 0px 0px 0.4em #ba1f00; */
}

.active h6 {
  color: #ba1f00;
}

.dont-end-button-dialog-drawer {
  background-color: #dbdbdb !important;
  font-weight: bold !important;
  color: #585757 !important;
  border-radius: 12px !important;
  font-size: 0.9em !important;
  padding: 20px 27px !important;
}

.end-button-dialog-drawer {
  background-color: #ba1f00 !important;
  font-weight: 500 !important;
  color: white !important;
  border-radius: 12px !important;
  font-size: 0.9em !important;
  padding: 0.7em 1.5em !important;
}

.overFlowXHidden {
  overflow-x: hidden !important;
}
