.ideaBoard3{
    display: table;
    width: 100%;
    position: relative;
    justify-content: space-around;
     
}
.goal{
    display: flex;
    left: 370px;
    position: relative;
    bottom: 50px;
}
.hea_der3{
    background-color: grey;
    position: relative;
    height: 110px;
    width: 62%;
    left: 358px;
    bottom: 50px;
   
}
.tex_t3{
    color: red;
    margin-left: 10px;
    position: relative;
    width: 290px;
    font-size: 17px;
    top: 20px;
}
.First3 {
    position: relative;
    background-color: white;
    display: table-cell;
    width: 318px;
    height: 507px;
    bottom: 130px;
}
.textbg3{
    display: flex;
    margin-left: 5px !important;
    width: 100px;
    margin-bottom: 10px;
}
._search3{
    margin-left: 20px;
    width: 150px;
    margin-bottom: 10px;
}
.title__header3{
    margin-left: 30px;
}
.Second3{
    
    position: relative;
    text-align: center;
    top: 10px;
    left: 5%;
  
    height: 280px;
    width: 28%;
    overflow: hidden;
    
}
.Third3 {
    position: relative;
    text-align: center;
    bottom: 270px;
    width: 28%;
    
    height: 280px;
    left: 35%;
    overflow: hidden;
    
}
.Four3 {
    position: relative;
    text-align: center;
    bottom: 550px;
    width: 28%;
  
    height: 280px;
    left: 65%;
    overflow: hidden;

}


.Ideal3{
    width: 308px;
   
    padding: 5px 5px 0px 5px;
    height: 300px;
    flex-direction: column;
    overflow-y: scroll;
}
.title3{
    display: flex;
    justify-content: center;
    color: #4375c1;
    width: 75%;
}


.item3{    
    display: flex;
    align-items: center;
    border: none !important;
    max-width: 450px;
    margin-bottom: 10px;
}

/* textarea.styles-module_Editext__input__2-M50 {
    height: 70px;
    width: 350px;
    margin-top: 10px;
} */

.ideas3{
  
    display: flex;
    padding: 5px 5px 0px 10px;
    align-items: baseline !important;
    justify-content: end;
    border: 1px solid #E2E2E2 !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    max-height: 100%;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    text-align: left;
    font: normal normal normal 12px/18px Heebo;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.startstop3{
    width: 318px;
    font-size: 12px !important;
    position: relative;
    bottom: 1130px;
    left: -318px;
    background: transparent 0% 0% no-repeat padding-box;
    box-shadow: 0px -3px 6px #00000029;
}
.IdeaBord_poll1_placeholdername3{
   
    position: relative;
   
    left: 33%;
    top: 45%;
    font-size: 30px;
    color: #7da0d5;
}

._done3{
    position: relative;
    width: 30px !important;
    top: 200px;
}

._search3{
    
    display: flex;
    margin-left: 10px;
    width: 125px;
    background: transparent  0% 0% no-repeat padding-box;
    box-shadow: 1px #00000029;
    opacity: 1;
    margin-bottom: 10px !important;
}
.my-custom-view-wrapper3 {
  display: flex;
  flex-direction: column;
}

.my-custom-view-wrapper3 div:last-child {
  /* Here we are styling the button container. */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.custom-edit-button3{
    position: relative;
   left: 59%;
    border: none;
    background-color: transparent;
}
.bottum_image3{
    position: relative;
    bottom: 800px;
    left: 8%;
    width: 20%;
    height: 180px;
}
.Alternative.\31 {
    background-color: #ffe25a;
    height: 250px;
    margin-top: 5px;
    border-radius: 10px;
    overflow-y: scroll;
}
.Alternative.\32 {
    background-color: #ffe25a;
    height: 250px;
    margin-top: 5px;
    border-radius: 10px;
    overflow-y: scroll;
}
.Alternative.\33 {
    background-color: #ffe25a;
    height: 250px;
    margin-top: 5px;
    border-radius: 10px;
    overflow-y: scroll;
}

.ideaBoardPoll3-drop-conatiner {
    background-color: #ffe25a;
    border-radius: 12px;
    padding: 1%;
    margin: 1%;
}
.hi_nt2 {
  
    position: relative;
    top: 40%;
    height: 10%;
    width: 80%;
    background-color: none !important;
}
img.casehint {
    width: 100%;
    position: relative;
    top: 25%;
}
.hintmain1 {
    /* height: 30%; */
    position: relative;
    /* top: 5%; */
    width: 90%;
    bottom: 11%;
    margin-bottom: 10px;
    /* background: rebeccapurple; */
    height: 23%;
}
.hintmain2 {
    height: 15%;
    width: 90%;
}
img.step2 {
    height: 100%;
}
.ideasre {
    padding: 5px 5px 0px 10px;
   
    max-height: 100%;
  
    border-radius: 10px;
    overflow: hidden;
    text-align: left;
    font: normal normal normal 12px/18px Heebo;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.ideasre:hover{
    text-decoration:none;
   background-color: white;
   color: #000;
   opacity: 1;
}