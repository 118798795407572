.horizontal-poll {
  position: absolute;
  overflow-y: auto;
  right: 25px;
  padding: 2% 2%;
  margin-top: 2%;
  background: #ffffff;
  box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
}

.ansContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2% 0% 2% 0%;
}
.inner-ansContainer {
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 700;
  width: "100%";
}
.horizontal-poll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.horizontal-poll::-webkit-scrollbar-track {
  box-shadow: none;
  margin: 10px;
}

.horizontal-poll::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  /* margin: 10px; */
  /* /* outline: 1px solid slategrey;  */
  border-radius: 3px;
}
