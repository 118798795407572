
.search-bar{ height: 54px; box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.04); 
  background-color: #ffffff; width: 100%; padding: 0px 1rem; display: 
  flex; align-items: center; border-radius: 4px}
.search-bar input{caret-color: #ffbc36; font-size: 1.125rem; line-height: 1.25rem; font-weight: 500;}
.search-text::before{border:none !important}
.search-text::after{border:none !important}
.conduct-dashboard .-headerTitle{
  flex-grow: 1; display: block;
}
.conduct-dashboard .-headerTitle p{ font-size: 14px }
.conduct-dashboard .-headerTitle h1{ font-size:26px }
.conduct-dashboard .dashboard-nav{
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin: 2rem 0rem 1.5rem;
}
.conduct-dashboard .dashboard-nav a{
  border-bottom: 1px dashed;
  text-decoration: none;
  font-size: 14px;
  margin-left: 2rem;
  color: #ba1f00;
}
.conduct-dashboard .dashboard-nav p:first-child{
  color: #8c8c8c;
  border-bottom: 1px transparent;
}
section{
  width: 100%;
  padding: 1.5rem 0;
}
.conduct-dashboard .grid-wrapper{
  display: grid;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.overlay-opacity {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
}
.tooltip {
  top:150px;
  left:120px;
  background-color: #fff9c2 !important;
}

.dvScroll {
  height: 490px;
  overflow-y: scroll;
  /* overflow: hidden; */
  width: 33%;
}