.yes-action-btn {
  background-color: #dbdbdb !important;
  font-weight: bold !important;
  color: #585757 !important;
  border-radius: 12px !important;
  font-size: 0.7em !important;
  padding: 1rem;
  width: 100%;
}

.no-action-btn {
  background-color: #ba1f00 !important;
  font-weight: 500 !important;
  color: white !important;
  border-radius: 12px !important;
  font-size: 0.7em !important;
  padding: 1rem;
  width: 100%;
}
.Img-yes-action-btn {
  background-color: #fff !important;
  font-weight: bold !important;
  color: #000 !important;
  border-radius: 12px !important;
  font-size: 0.7em !important;
  padding: 1rem;
  border: 1px solid #ba1f00 !important;
  width: 100%;
}

.Img-no-action-btn {
  background-color: #ba1f00 !important;
  font-weight: 500 !important;
  color: white !important;
  border-radius: 12px !important;
  font-size: 0.7em !important;
  padding: 1rem;
  width: 100%;
}
