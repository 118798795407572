.with-left-nav.wrapper {
  padding: 2.5rem;
  width: calc(100% - 70px);
  height: 100vh;
  overflow: auto;
  background-color: #fafafa;
}

.with-left-nav.wrapper .expandable-header-bar-root {
  box-shadow: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
}

.with-left-nav.wrapper .expandable-header-bar-root:before {
  height: 0px
}

.with-left-nav.wrapper .expandable-header-panel-root {
  margin: 0px;
  padding: 0px;
}

.search-bar {
  height: 54px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  width: 100%;
  padding: 0px 1rem;
  display: flex;
  align-items: center;
  border-radius: 4px
}

.search-bar input {
  caret-color: #ffbc36;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.search-text::before {
  border: none !important
}

.search-text::after {
  border: none !important
}

section {
  width: 100%;
  padding: 1.5rem 0;
}



.filterStyles {
  display: flex;
  width: 25%;
  justify-content: flex-end;
  align-items: center;
  place-self: flex-start;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay-opacity {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
}

.tooltip {
  top: 150px;
  left: 120px;
  background-color: #fff9c2 !important;
}

.novaltext {
  color: #8c8c8c;
  font-size: 14px;
}

.circular-progress-color {
  color: #ba1f00 !important;
}