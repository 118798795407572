
.status-icon {
  position: absolute;
  top: 1.25rem;
  right: 1.785rem
}

.cFoot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cFoot .progress-root {
  width: 120px;
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.cFoot .progress-bar {
  border-radius: 5px;
  background-color: rgb(198, 146, 42);
}

.MuiLinearProgress-barColorPrimary {
  background-color: #c6922a !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #ebebeb !important;
}

/* .MuiDialog-paper{
  width: 300px;
  position: absolute !important;
  left: 37px;
  top: -20px;
  box-shadow: none !important;
  overflow:visible !important;
  background: #fff9c2 !important;
} */
.MuiDialog-paper::before{
  content: '';
  position: absolute;
  display: block;    
  width: 0px;        
  left: 5px;
  top: 16%;
  border: 13px solid transparent;
  border-left: 0;
  border-right: 13px solid #fff9c2;
  transform: translate(calc(-100% - 5px), -50%);
} 