.content {
  line-height: 2.25rem;
  /* margin: 1.5rem 1.5rem; */
  /* width: 100%; */
  /* padding-right: 15%; */
  width: 90%;
  height: 100%;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
}

.your-batches {
  font-weight: bold;
  line-height: 2.25rem;
  margin: 1.5rem 0rem;
  padding-right: 15%;
  font-size: 2rem;
}

.rowName {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.assessment-code-text {
  color: #999;
  font-size: 0.7rem;
}

.assessment-cell:hover {
  background-color: #eee;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: transparent !important;
}

.head-text {
  color: #666;
  font-weight: 500;
}

.bold-center-text {
  font-weight: bold;
  text-align: center;
}

.text-center {
  text-align: center;
}

.small-text {
  font-size: 20px;
}

.large-text {
  font-size: 32px;
}

.flex {
  display: flex;
}

.justify-around {
  justify-content: space-around;
}
.link {
  color: black;
}

.bg-gray {
  background-color: #eee;
}

.flex-col {
  flex-direction: column;
}

.w-50 {
  width: 50%;
}

.fit-content {
  width: fit-content;
}
