.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .overlay-opacity {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
  }
  .access-card{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .-access-card-body{
    max-width: 400px;
    text-align: center;
  }
  .-access-card-body .subtitle{
    text-align: center;
  }
  .access-card-content{
    background: #fff;
    border-radius: 4px;
    padding: 1.875rem;
    box-shadow: 0 0 10px 0 rgba(64, 47, 13, 0.05);
    border: solid 1px #ebebeb;
  }
  .access-btn-inline{
    display: flex;
  }
  .access-btn-inline .MuiOutlinedInput-input{
    padding: 10px 14px;
  }
  /* .MuiInputLabel-outlined{
    transform: translate(14px, 14px) scale(1) !important;
  } */
  .access-card-content h6{
    margin-bottom: 15px;
  }
  .errorMsg{
    color: red !important;
  }