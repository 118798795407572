
.search-bar {
  height: 54px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  width: 100%;
  padding: 0px 1rem;
  display: flex;
  align-items: center;
  border-radius: 4px
}

.search-bar input {
  caret-color: #ffbc36;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.search-text::before {
  border: none !important
}

.search-text::after {
  border: none !important
}

.prepare-dashboard .-headerTitle {
  flex-grow: 1;
  display: block;
}

.prepare-dashboard .-headerTitle p {
  font-size: 14px
}

.prepare-dashboard .-headerTitle h1 {
  font-size: 26px
}

.prepare-dashboard .dashboard-nav {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin: 2rem 0rem 1.5rem;
}

.prepare-dashboard .dashboard-nav a {
  text-decoration: none;
  font-size: 14px;
  margin-left: 0.5rem !important;
  color: #ba1f00;
}

.filterStyles {
  display: flex;
  width: 25%;
  justify-content: flex-end;
  align-items: center;
  place-self: flex-start;
}

.explore-titles {
  margin: 1.25rem 0rem;
  display: flex;
  align-items: center;
}

.prepare-dashboard .dashboard-nav p:first-child {
  color: #8c8c8c;
  border-bottom: 1px transparent;
}

section {
  width: 100%;
  padding: 1.5rem 0;
}

.prepare-dashboard .grid-wrapper {
  /* display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px; */
  /* display: grid;
  grid-template-rows: auto;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, 274px); */
  display: grid;
  grid-template-rows: auto;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, 255px);
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay-opacity {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
}

.tooltip {
  top: 150px;
  left: 120px;
  background-color: #fff9c2 !important;
}

.novaltext {
  color: #8c8c8c;
  font-size: 14px;
}