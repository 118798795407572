.batch-card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 2px 4px #d2d2d229;
    border: 2px solid #eeeeee;
    border-radius: 22px;
    margin: 12px 0px;
    // margin-bottom: 10px;
    padding: 20px;
    width: fit-content;
    max-width: 94%;

    span {
        &.key {
            font-weight: 400;
            margin-right: 10px;
        }

        &.value {
            margin-right: 30px;
            padding: 2px 10px;
            font-weight: 500;
            background: #f6f6f6 0% 0% no-repeat padding-box;
            border-radius: 8px;
        }
        &.value:last-child {
            margin-right: 0px;
        }
    }

    .assessment-status {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 35px;
        font-size: 0.8em;

        .completed {
            background: #f6f6f6 0% 0% no-repeat padding-box;
            border-radius: 8px;
            text-align: center;
            color: #898989;
            font-size: 1em;
            line-height: 1.2;
            padding: 15px;

            div span:first-child {
                font-weight: bold;
                color: black;
                font-weight: 600;
                font-size: 38px;
                line-height: 16px;
            }
        }
    }

    &.w-auto {
        width: auto;
    }
}

.batch-rename-button {
    text-decoration: underline !important;
    letter-spacing: 0px !important;
    color: #6b6b6b !important;
    text-transform: inherit !important;
}

.batch-tooltip {
    background: #f8f8f8 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #898989 !important;
    border-radius: 8px !important;
    padding: 12px !important;

    .info-text {
        color: black;
        font-size: 14px;
        display: flex;
        align-items: center;
    }

    .text-contanier {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #898989;
        padding: 12px 0px;
    }
}

.batch-tooltip-size_1 {
    max-width: 450px !important;
}
.batch-tooltip-size_2 {
    max-width: 200px !important;
}
