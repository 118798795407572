.feedback-title {
    font-weight: bolder;
    margin-top: 1em;
    margin-left: 1.4em;
    color: #000000 !important;
    position: relative;
}

.feedback-title::before {
    content: "\2022";
    font-weight: bold; 
    font-size: 3em;
    position: absolute;
    display: inline-block;
    left: -0.5em;
    top: -0.45em
}

.feedback-title.red::before {
    color: #e71d25;
}

.feedback-title.yellow::before {
    color: #ffaf29;
}

.feedback-title.green::before {
    color: #7db82c;
}

.grey {
    color: #9E9E9E;
    margin-left: 1.4em;
}

.improvements {
    background-color: #E0E0E0;
    padding: 2em;
    display: block;
    margin-top: 1em;
    position: relative;
}

.improvements .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.improvements li {
    margin-left: 2em;
}

.improvements .wrapper {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.how-to-improve {
    color: #be2d13;
    margin-top: 1em;
    font-weight: bolder;
    margin-left: 1.4em;
    display: block;
    position: relative;
}

.how-to-improve .icon {
    position: absolute;
}

.container {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}

.suggestions li {
    list-style: none;
    position: relative;
    color: #666666;
    font-size: 14px;
    margin-top: 10px;
}

.suggestions li::before {
    content: "\2022";
    font-weight: bold; 
    font-size: 1.5em;
    color: #8c8c8c;
    position: absolute;
    display: inline-block;
    left: -1em;
    top: -0.2em
}

.best-practices {
    display: inline;
}

.feedback-wrapper {
    margin-top: 15px;
}

.best-practices .from::before, .best-practices .to::before {
    content: "\2022";
    font-weight: bold; 
    font-size: 3em;
    display: inline-block;
    position: absolute;
    top: -0.45em;
}

.best-practices .from::before {
    left: 5px;
}

.best-practices .to::before {
    left: 40px;
}

.best-practices.red .from::before {
    color: #e71d25;
}

.best-practices.red .to::before {
    color: #ffaf29;
}

.best-practices.yellow .from::before {
    color: #ffaf29;
}

.best-practices.yellow .to::before {
    color: #7db82c;
}

.best-practices .arrow {
    position: absolute;
    top: -1px;
    color: black;
    left: 20px;
}

.best-practices .text {
    margin-left: 70px;
    color: black;
}

.best-practices .icons {
    width: 30px;
    position: relative;
    display: inline;
}

.feedback-image-wrapper {
    display: inline-block;
}

.feedback-image-wrapper .grey {
    float: left;
    width: 80%;
    text-align: left;
}

.feedback-image-wrapper .img {
    float: left;
    height: 102px;
}