.prepare-pg .prep-tabs-indicator {
  height: 0px
}

.prepare-pg .prep-tab-root {
  border-radius: 16px 16px 0px 0px;
  background-color: #465a7b;
  margin-right: 10px;
}

.prep-tab-root.prep-tab-selected {
  background-color: #fafafa;
  color: #000000
}


.module-title {
  display: flex;
  align-items: center; color: #E4001B;
  padding-top: 0px !important;
  font-size: 18px !important; line-height: 48px;
}

.module-title a {
  display: flex;
  /* margin: 0px 30px; */
}

.prepare-bg {
  background-color: #FFE8E3;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tool-card {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 2rem 2rem 0.5rem;
  max-width: 450px;
  background-color: #fff;
}

.module-card-body {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 20px 0px;
  overflow-y: scroll;
  border: 1px solid #e4e4e4;
}

.mild-text {
  opacity: 0.5;
  font-size: 0.95rem;
  font-weight: 500;
}

.module-heading {
  display: flex;
  justify-content: space-between;
  line-height: 50px;
  align-items: baseline;
}

.estd-time {
  font-size: small;
  font-weight: 400;
  opacity: 0.5;
}

.mb-2{
  margin-bottom: 0.875rem;
}
.p-5{
  padding:2.5rem 4.2rem;
}

.flex-column{
  flex-direction: column;
}
.flex-wrap{
  flex-wrap:wrap;
}
.left-card{
  width:250px;
  max-width:100%;
  border-radius:6px;
  padding:1rem;
}
.left-card ul{
  list-style:none;
  margin:0px;
  padding:0px;
  display:block;
  border-radius: 6px;
  background: #fff !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebebeb;

}
.left-card ul li{
  display:block
}

.left-card ul li a {
  padding: 13px 15px !important;
  display: block;
  text-decoration: none;
  color: #292929;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.right-card{
  width: calc(100vw - 480px);
  max-width:100%;
  border-radius:6px;
  padding:1rem;
}
.row{
  margin-left:-15px;
  margin-right:-15px;
}
.col{
  width:auto;
  max-width:100%;
  padding:0 15px;
}
.card{
  width:100%;
  max-width:100%;
  border-radius:6px;
  margin-bottom: 15px;
  background: #fff !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebebeb;
}
.card-body{
  padding:1rem;
}
.clsHead{
  font-size: 16px;
  line-height: 26px;  
  font-weight: bold;
  font-family: Heebo;
  margin-bottom: 1rem;
}
.clsDet{
  font-size: 14px;
  line-height: 26px;  
  letter-spacing: 0.42px;
  font-family: Heebo;
  padding-top: 10px;
}
.activeClass {
  font-family: Heebo;
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: #ba1f00;
}
.inactiveClass {
  font-family: Heebo;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  color: #000000;
}
.MuiTab-root{
  text-transform: capitalize !important;
}

.prep-description {
  /* padding: 1rem 0rem; */
  line-height: 26px; 
  font-size: 0.95rem;
}

.prep-description p {
  /* margin-top: 1rem; */
}

.prep-description ul{
 margin-left: 1.1rem
}