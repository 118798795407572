.content-roll-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    // background-color: rebeccapurple;
    height: 100%;
}
.content-roll-top{
    padding: 7px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #494949;
}
.content-roll-top-title{
    font-size: 17px;
    font-weight: 600;
    color: white;
}
.content-roll-top-label{
    font-size: 17px;
    background-color: #BA1F00;
    padding: 3px 10px;
    color: white;
}
.back-btn{
    background-color: transparent;
    border: 0;
    font-size: 17px;
    font-weight: 500;
    color: rgb(216, 216, 216);
    cursor: pointer;
}
.back-btn:hover{
    color: white;
}
.content-roll-img-container{
    height: 100%;
}
.content-roll--img{
    // width: 100%;
    height: 100%;
    // border: 2px solid black;
    // border-radius: 6px;
}

.content-roll-controller{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10%;
    // gap: 20px;
}

$btnbBorderRadius: 10px;
.controller-btns{
    padding: 8px;
    width: 250px;
    border: 2px solid black;
    background-color: rgb(255, 188, 54);
    border-radius: $btnbBorderRadius;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.controller-btns--left{
    border-radius: $btnbBorderRadius 0 0 $btnbBorderRadius;
    border-right: 1px solid black;
}
.controller-btns--right{
    border-radius: 0 $btnbBorderRadius $btnbBorderRadius 0;
    border-left: 1px solid black;
}
.content-roll-player{
    display: flex;
    // align-items: center;
    justify-content: space-between;
    height: calc(100% - 60px);
    width: 100%;
    // background-color: red;
}
.controller-btns-2{
    background-color: black;
    color: white;
    font-size: 20px;
    color: white;
    padding: 0 10px;
    border: 0;
    width: 100%;
    cursor: pointer;
}
.controller-btns-2:hover{
    background-color: rgb(26, 26, 26);
}